import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroSection from '../components/HeroSection';
import ChartSection from '../components/ChartSection';
import TableSection from '../components/TableSection';
import FooterSection from '../components/FooterSection';
import InTheNewsSection from '../components/InTheNewsSection';
import Signup from '../components/Signup';
import EcoChartVisx from '../components/EcoChart';
import { useMediaQuery } from 'react-responsive';
import { ParentSize } from '@visx/responsive';
import data from '../../uploads/natlUnemploymentData.json';

const IndexPage = ({ pageContext: { pilots, stories } = {} }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const triggerRef = useRef();

  return (
    <Layout showBanner={true}>
      <SEO title="Home" image="https://guaranteedincome.us/images/gainesville-calvin.jpg" />
      <main className="flex-grow flex flex-col lg:h-[860px] bg-forest py-4 lg:py-0">
        <HeroSection />
      </main>
      <section className="flex-grow flex flex-col bg-tan">
        <ChartSection />
      </section>
      <section className="flex-grow flex flex-col">
        <TableSection pilots={pilots} />
      </section>
      <section className="bg-tan flex-grow">
        <div className="container mx-auto flex flex-col justify-center place-content-center place-items-center">
          <h1 className={'text-center mt-10 mb-5 max-w-[520px]'}>
            Economic Context for Pilot Participants
          </h1>
          <div className="w-full sm:w-[58%] text-center px-2" ref={triggerRef}>
            <p>
              The guaranteed income pilots featured in the Dashboard launched during the COVID-19
              Pandemic, a period of dynamic change and economic challenges for pilot participants.
              The chart below situates these pilots in the context of unemployment and inflation.
              Use the button at the bottom to toggle between viewing economic data and pilot
              timelines, and mouse over the chart for more detail.
            </p>
          </div>
          {isMobile ? (
            <div className="h-full w-full px-2">
              <EcoChartVisx
                margin={{
                  top: 0,
                  right: 0,
                  bottom: 100,
                  left: 70,
                }}
                triggerRef={triggerRef}
                width={1000}
                height={500}
                pilots={pilots}
                isHomepage={true}
                unemploymentData={data}
              />
            </div>
          ) : (
            <div
              style={{ height: '500px' }}
              className="mb-[400px] lg:mb-[280px] h-full w-full pl-24 pr-[5.5rem] md:pr-[3.5rem]"
            >
              <ParentSize>
                {({ width, height }) => (
                  <EcoChartVisx
                    margin={{
                      top: 0,
                      right: 30,
                      bottom: 100,
                      left: 40,
                    }}
                    triggerRef={triggerRef}
                    pilots={pilots}
                    height={height}
                    width={width}
                    isHomepage={true}
                    unemploymentData={data}
                  />
                )}
              </ParentSize>
            </div>
          )}
        </div>
      </section>
      <section className="bg-[#00505C]">
        <FooterSection stories={stories} />
      </section>
      <section className="flex-grow flex flex-col py-8 lg:py-10">
        <InTheNewsSection />
      </section>
      <Signup />
    </Layout>
  );
};

export default IndexPage;
