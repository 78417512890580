import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Chart from './chart';
import { spendingMap, hasValidSpendingValues } from '../utils/spending';
import { formatNumber } from '../utils/formatters';
import AnimatedNumber from '../components/AnimatedNumber';

function App() {
  const data = useStaticQuery(graphql`
    query spendingData {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/landing.md/" } }) {
        edges {
          node {
            frontmatter {
              totalParticipants
              spendingBreakdown
              spending {
                retail
                entertainment
                education
                financial
                transport
                misc
                healthcare
                housing
                food
              }
            }
          }
        }
      }
    }
  `);

  const {
    totalParticipants,
    spendingBreakdown,
    spending: initialSpending,
  } = data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter ?? {};

  const spending = Object.entries(initialSpending)
    .map(([key, value]) => ({ label: spendingMap[key], value }))
    .sort((a, b) => b.value - a.value);

  return (
    <div
      id="pilotschart"
      className="container items-center mx-auto flex px-3 md:px-5 lg:px-0 my-6 flex-wrap md:flex-nowrap gap-4 sm:gap-6 md:gap-8"
    >
      <div className="flex-1 flex justify-center">
        <div className="h-[604px] lg:h-[380px] w-[250px] sm:w-[300px] lg:min-w-[600px]">
          <Chart spending={spending} />
        </div>
      </div>
      <div className="flex flex-col justify-center flex-1 lg:-mt-5">
        <AnimatedNumber className="bigstat text-center md:text-left" value={totalParticipants} />
        <div className="headings mb-3 text-center md:text-left">
          TOTAL PARTICIPANTS WITH SPENDING DATA
        </div>
        <ReactMarkdown children={spendingBreakdown} />
      </div>
    </div>
  );
}
export default App;
