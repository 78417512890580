import React, { useRef, useState } from 'react';
import Carousel from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StaticImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'gatsby';
import { LeftArrowIcon, RightArrowIcon } from 'src/assets/svg';

const FooterSection = ({ stories, ...rest }) => {
  const carouselRef = useRef(null);
  const topStories = [
    stories.find((story) => story.participantName === 'Crystal'),
    stories.find((story) => story.participantName === 'Christina'),
    stories.find((story) => story.participantName === 'Calvin'),
    stories.find((story) => story.participantName === 'Katey'),
  ].filter((story) => !!story);

  const [currentSlide, setCurrentSlide] = useState(0);

  const carouselSettings = {
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 8000,
    afterChange: (current) => setCurrentSlide(current),
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.slickNext();
    }
  };

  const renderControls = () => (
    <div className="flex justify-center mt-4 lg:mt-5 lg:w-[518px]">
      <button onClick={handlePrev}>
        <LeftArrowIcon />
      </button>
      <p className="text-white text-[13.2px] mx-5 mb-0">{`${currentSlide + 1} / ${
        topStories.length
      }`}</p>
      <button onClick={handleNext}>
        <RightArrowIcon />
      </button>
    </div>
  );

  return (
    <div>
      <Carousel {...carouselSettings} ref={carouselRef}>
        <Slide renderControls={renderControls} item={topStories[0]}>
          <img
            className={`w-full sm:w-[518px] h-[304px] sm:h-[381px] object-cover`}
            src={'/images/atlanta-crystal.jpg'}
            alt={'Crystal'}
          />
        </Slide>
        <Slide renderControls={renderControls} item={topStories[1]}>
          <img
            className={`w-full sm:w-[518px] h-[304px] sm:h-[381px] object-cover`}
            src={'/images/new-york-city-christina.jpg'}
            alt={'Christina'}
          />
        </Slide>
        <Slide renderControls={renderControls} item={topStories[2]}>
          <img
            className={`w-full sm:w-[518px] h-[304px] sm:h-[381px] object-cover`}
            src={'/images/gainesville-calvin.jpg'}
            alt={'Calvin'}
          />
        </Slide>
        <Slide renderControls={renderControls} item={topStories[3]}>
          <img
            className={`w-full sm:w-[518px] h-[304px] sm:h-[381px] object-cover`}
            src={'/images/st-paul-springboard-katey.jpg'}
            alt={'Katey'}
          />
        </Slide>
      </Carousel>
    </div>
  );
};

const Slide = ({ item, renderControls, children }) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return (
    <div>
      <div
        className={`${item.slug}-slider w-full flex flex-col lg:flex-row items-center py-5 sm:py-8 lg:mt-0 px-0 lg:px-[112px] xl:px-[128px] 2xl:px-[320px]`}
      >
        <div className="w-full md:pr-3 lg:w-1/2 flex lg:block justify-center">
          {children}
          {isDesktop && renderControls()}
        </div>
        <div
          className={`w-full lg:w-1/2 flex flex-col items-center text-white px-3 sm:px-13 lg:px-0`}
        >
          <h1 className="text-center mt-4 sm:mt-5 lg:mt-0">{item.participantName}</h1>
          <p className="text-center text-[18px] sm:text-[21px] font-[800] mt-4 sm:mt-5 lg:mt-6">
            {item.pullQuote}
          </p>
          <p className="text-center text-[13px] sm:text-[14px] font-[400] mt-4 sm:mt-5 lg:mt-6">
            {item.pilotName}
          </p>
          <p className="text-center text-[13px] sm:text-[14px] font-[400] mt-[13px]">
            {item.pilotLocation}
          </p>
          <Link to={`/stories/${item.slug}`}>
            <button className="text-white text-[13px] sm:text-[15px] !font-[800] tracking-[1.2px] w-[154px] sm:w-[176px] lg:w-[161px] h-[48px] sm:h-[66px] bg-[#B26F16] mt-4 sm:mt-5 lg:mt-6">
              VIEW STORIES
            </button>
          </Link>
          {!isDesktop && renderControls()}
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
