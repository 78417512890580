import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

const InTheNewsSection = () => {
  const data = useStaticQuery(graphql`
    query newsItems {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/in-the-news.md/" } }) {
        edges {
          node {
            frontmatter {
              newsStories {
                date
                link
                source
                title
              }
            }
          }
        }
      }
    }
  `);

  const items = data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter?.newsStories;

  items.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  const newsItems = items.slice(0, 3);

  const NewsItem = (item) => {
    const data = item.data;

    const date = new Date(data.date).toLocaleDateString(undefined, {
      month: 'long',
      year: 'numeric',
    });

    return (
      <div className="news-item block flex-col basis-1/3 mb-5 lg:mb-0 last:mb-0">
        <a className="block text-black h-full" href={data.link} target="_blank">
          <span className="flex text-[12px] lg:text-[14px] leading-[18px] lg:leading-[20px] tracking-[1.8px] uppercase font-bold mb-3">
            {date} - {data.source}
          </span>
          <h5 className="text-black text-[18px] lg:text-[21px] leading-[24px] lg:leading-[1.5] tracking-[.1px] font-bold">
            {data.title}
          </h5>
        </a>
      </div>
    );
  };

  return (
    <div id="in-the-news" className="container mx-auto px-3 lg:px-0">
      <div className="bg-tan px-4 md:px-8 lg:px-11 py-6 lg:py-8 lg:pt-7">
        <h1 className="mb-5 lg:mb-6 text-[24px] md:text-[36px] lg:text-[40px] text-center">
          In The News
        </h1>
        <div className="flex flex-col lg:flex-row gap-x-5 lg:gap-x-8">
          {newsItems.map(function (item, i) {
            return <NewsItem data={item} key={i} />;
          })}
        </div>
        <div className="flex mt-5 lg:mt-7 justify-center">
          <Link to="in-the-news">
            <button className="text-white uppercase text-[13px] md:text-[14px] !font-[800] tracking-[1.8px] w-[218px] md:w-[260px] h-[48px] md:h-[66px] bg-[#B26F16]">
              View All News
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InTheNewsSection;
