import { animate, cubicBezier, motion, useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

const getNumDigits = (num) => {
  if (Number.isInteger(num)) {
    return 0;
  }

  return num.toString().split('.')[1].length;
};

const AnimatedNumber = ({
  value: endValue = 0,
  duration = 1,
  formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: getNumDigits(endValue),
  }),
  triggerRef,
  ...props
}) => {
  const nodeRef = useRef();
  const isInView = useInView(triggerRef || nodeRef, { once: true });

  useEffect(() => {
    if (isInView) {
      const node = nodeRef.current;
      const controls = animate(0, endValue, {
        duration,
        ease: cubicBezier(1, 0.82, 0.3, 1),
        onUpdate(value) {
          node.textContent = formatter.format(value);
        },
      });

      return () => controls.stop();
    }
  }, [endValue, duration, isInView, formatter]);

  return (
    <motion.div ref={nodeRef} {...props}>
      0
    </motion.div>
  );
};

export default AnimatedNumber;
