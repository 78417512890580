import React, { useEffect, useState } from 'react';
import { GrFacebookOption, GrTwitter } from 'react-icons/gr';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from '@reach/router';

const sortByString = (data, filter, ascending = true) =>
  data.sort((a, b) => {
    const aVal = typeof filter === 'function' ? filter(a) : a[filter];
    const bVal = typeof filter === 'function' ? filter(b) : b[filter];

    return ascending
      ? aVal.toLowerCase().localeCompare(bVal)
      : bVal.toLowerCase().localeCompare(aVal);
  });

const sortByNumber = (data, filter, ascending = true) =>
  data.sort((a, b) => {
    const aVal = typeof filter === 'function' ? filter(a) : a[filter];
    const bVal = typeof filter === 'function' ? filter(b) : b[filter];

    if (isNaN(+bVal)) return 1;

    return ascending ? +aVal - +bVal : +bVal - +aVal;
  });

function TableSection({ pilots }) {
  const data = useStaticQuery(graphql`
    query data {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/all.md/" } }) {
        edges {
          node {
            frontmatter {
              lastUpdated
            }
          }
        }
      }
    }
  `);

  const { lastUpdated } = data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter ?? {};

  const [sortedData, setSortedData] = useState();
  const [sortOrders, setSortOrders] = useState({
    location: false,
    participants: false,
    amount: false,
    duration: false,
  });

  const location = useLocation();

  useEffect(() => {
    let pilotData = pilots.filter((data) => !!data.pilotCity);
    pilotData = pilotData.map((values) => {
      if (values.duration) {
        const duration = values.duration.split(' ');

        let months = duration[0];

        if (duration[1].toLowerCase() === 'year' || duration[1].toLowerCase() === 'years') {
          months = months * 12;
        }
        return { ...values, months };
      }
      return values;
    });

    setSortedData(
      sortByString(pilotData, 'pilotCity').sort((a, b) => {
        if (a.comingSoon) return 1;
        if (b.comingSoon) return -1;
        return 0;
      }),
    );
  }, []);

  return (
    <div id="pilotstable" className="container mx-auto px-2 lg:px-0 sm:py-5 my-5 flex-grow">
      <div className="flex justify-center md:justify-end mb-4 md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0 ">
        <div className="headings border border-black px-5 py-2 flex items-center">
          Share Via{' '}
          <FacebookShareButton
            url={location.href}
            description="Explore Guaranteed Income pilots across the U.S., and hear stories from participants at guaranteedincome.us"
          >
            <GrFacebookOption className="text-2xl mx-1 cursor-pointer" />
          </FacebookShareButton>
          <TwitterShareButton
            title="Explore Guaranteed Income pilots across the U.S., and hear stories from participants at guaranteedincome.us"
            url={location.href}
          >
            <GrTwitter className="text-2xl cursor-pointer" />
          </TwitterShareButton>
        </div>
      </div>
      <div className="flex justify-center md:-mt-4 lg:-mt-5 mb-3 uppercase">
        <p className="headings">Guaranteed income pilots</p>
      </div>
      <div className="flex justify-center">
        <h1 className="text-center">
          Get the latest data on <br /> pilots around the country
        </h1>
      </div>
      <div className="flex overflow-x-scroll md:overflow-x-visible">
        <div className="table w-full min-w-[720px]">
          <table className="table-fixed w-full mt-5">
            <thead
              className="sticky top-[0px] md:top-[73px] text-left text-white"
              style={{ backgroundColor: '#00505C' }}
            >
              <tr>
                <th
                  className="py-3 cursor-pointer select-none"
                  onClick={() => {
                    setSortedData(sortByString(sortedData, 'pilotCity', !sortOrders.location));
                    setSortOrders({ ...sortOrders, location: !sortOrders.location });
                  }}
                >
                  Location
                  <FontAwesomeIcon className="ml-1" icon={faSort} />
                </th>
                <th
                  className="cursor-pointer select-none"
                  onClick={() => {
                    setSortedData(
                      sortByNumber(sortedData, 'participants', !sortOrders.participants),
                    );
                    setSortOrders({ ...sortOrders, participants: !sortOrders.participants });
                  }}
                >
                  # of Participants <FontAwesomeIcon className="ml-1" icon={faSort} />
                </th>
                <th
                  className="cursor-pointer select-none whitespace-nowrap"
                  onClick={() => {
                    setSortedData(sortByNumber(sortedData, (x) => x.amount, !sortOrders.amount));
                    setSortOrders({ ...sortOrders, amount: !sortOrders.amount });
                  }}
                >
                  Monthly Payment <FontAwesomeIcon className="ml-1" icon={faSort} />
                </th>
                <th
                  className="cursor-pointer select-none"
                  onClick={() => {
                    setSortedData(sortByNumber(sortedData, 'months', !sortOrders.duration));
                    setSortOrders({ ...sortOrders, duration: !sortOrders.duration });
                  }}
                >
                  Duration <FontAwesomeIcon className="ml-1" icon={faSort} />
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {sortedData &&
                sortedData.length > 0 &&
                sortedData.map((item) => (
                  <tr key={item.pilotCity}>
                    <td>{item.pilotCity ?? '--'}</td>
                    <td>{item.participants ?? '--'}</td>
                    <td>
                      {item.amount
                        ? item.amount.toString().startsWith('$')
                          ? item.amount
                          : `$${item.amount}`
                        : '--'}
                    </td>
                    <td>{item.duration ?? '--'}</td>
                    <td>
                      {!item.comingSoon ? (
                        <Link to={item.slug}>
                          <span className="text-bronze tracking-[0.1rem] font-medium">
                            Learn More
                          </span>
                        </Link>
                      ) : (
                        <span className="text-slate tracking-[0.1rem] font-medium opacity-50">
                          Coming Soon
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <p className="text-gray-700 w-full text-center font-medium my-4">{lastUpdated}</p>
    </div>
  );
}
export default TableSection;
