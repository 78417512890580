import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

function App() {
  return (
    <div className="container mx-auto px-3 md:px-5 lg:px-0 flex-grow h-full">
      <div className="items-center flex lg:h-full flex-col lg:flex-row lg:flex-nowrap md:mt-4 lg:mt-0">
        <motion.div
          className="order-1 lg:order-0 flex-1 basis-1/2 flex flex-col justify-center lg:pr-4"
          initial={{ y: '5%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ bounce: 0, delay: 0.2, duration: 0.8 }}
        >
          <h1 className="text-white">The Guaranteed Income Pilots Dashboard</h1>
          <p className="text-white my-5">
            The Stanford Basic Income Lab and Center for Guaranteed Income Research have partnered
            to visualize data from evaluations of 30+ guaranteed income pilots across the United
            States. New demonstrations are added as their data becomes available.
          </p>
          <Link to="/about" className="text-white cta1">
            LEARN MORE about the project and our partners
          </Link>
          <AnchorLink to="/#pilotschart" className="text-white cta1 my-3">
            VIEW DATA FROM PILOTS
          </AnchorLink>
          <div className="md:mt-4 lg:mt-5 xl:mt-5">
            <p className="text-white text-14 lg:text-16 uppercase !font-medium mb-2">
              A Project Of
            </p>
            <a
              href="https://basicincome.stanford.edu/"
              target="_blank"
              rel="noreferrer noopener"
              className="flex flex-wrap items-center divide-x"
            >
              <div className="flex items-center flex-shrink-0 mr-1 text-white ">
                <img src="/images/stanfordlogo.svg" />
              </div>
              <div className="text-base text-white pl-1">Basic Income Lab</div>
            </a>
            <div className="flex flex-wrap my-3 gap-2">
              <a href="https://www.mayorsforagi.org/" target="_blank" rel="noreferrer noopener">
                <img
                  className="relative left-[-18px]"
                  src="/images/mgilogo.png"
                  alt="MGI logo"
                  quality={100}
                />
              </a>
              <a href="https://www.penncgir.org/" target="_blank" rel="noreferrer noopener">
                <img src="/images/cgir-logo.svg" alt="CIGR logo" quality={100} />
              </a>
            </div>
          </div>
        </motion.div>
        <div className="order-0 lg:order-1 flex-1 basis-1/2 grid grid-cols-11 grid-rows-9 gap-[4%] w-full md:w-[80%] lg:w-full mb-2 md:mb-5 lg:mb-0 h-fit aspect-[11/9]">
          <motion.div
            className="h-full w-full overflow-hidden col-start-1 col-span-2 row-start-4 row-span-3"
            initial={{ y: '-50%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.75, bounce: 0 }}
          >
            <StaticImage
              className="h-full w-full object-cover"
              src="../../static/images/hero/tacoma-stephanie.png"
              alt="image of pilot participant"
            />
          </motion.div>
          <motion.div
            className="h-full w-full overflow-hidden col-start-3 col-span-3 row-start-1 row-span-5"
            initial={{ y: '-50%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.75, bounce: 0 }}
          >
            <StaticImage
              className="h-full w-full object-cover"
              src="../../static/images/hero/atlanta-crystal.png"
              alt="image of pilot participant"
            />
          </motion.div>
          <motion.div
            className="h-full w-full overflow-hidden col-start-6 col-span-4 row-start-2 row-span-3"
            initial={{ y: '-50%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.75, bounce: 0 }}
          >
            <StaticImage
              className="h-full w-full object-cover"
              src="../../static/images/hero/louisville-hero.png"
              alt="image of pilot participant"
            />
          </motion.div>
          <motion.div
            className="h-full w-full overflow-hidden col-start-10 col-span-2 row-start-3 row-span-2"
            initial={{ y: '-50%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.75, bounce: 0 }}
          >
            <StaticImage
              className="h-full w-full object-cover"
              src="../../static/images/hero/st-paul-springboard-katey.png"
              alt="image of pilot participant"
            />
          </motion.div>
          <motion.div
            className="h-full w-full overflow-hidden col-start-9 col-span-2 row-start-5 row-span-2"
            initial={{ y: '50%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.75, bounce: 0 }}
          >
            <StaticImage
              className="h-full w-full object-cover"
              src="../../static/images/hero/new-york-city-hero.png"
              alt="image of pilot participant"
            />
          </motion.div>
          <motion.div
            className="h-full w-full overflow-hidden col-start-6 col-span-3 row-start-5 row-span-5"
            initial={{ y: '50%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.75, bounce: 0 }}
          >
            <StaticImage
              className="h-full w-full object-cover"
              src="../../static/images/hero/gainesville-calvin.png"
              alt="image of pilot participant"
            />
          </motion.div>
          <motion.div
            className="h-full w-full overflow-hidden col-start-3 col-span-3 row-start-6 row-span-3"
            initial={{ y: '50%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.75, bounce: 0 }}
          >
            <StaticImage
              className="h-full w-full object-cover"
              src="../../static/images/hero/petus-nov.png"
              alt="image of pilot participant"
            />
          </motion.div>
          <motion.div
            className="overflow-hidden col-start-2 col-span-1 row-start-7 row-span-1 -ml-[20px] w-[calc(100%+20px)] h-[calc(100%+20px)] -mb-[20px]"
            initial={{ y: '50%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.75, bounce: 0 }}
          >
            <StaticImage
              className="h-full w-full object-cover"
              src="../../static/images/hero/tacoma-geno.png"
              alt="image of pilot participant"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default App;
